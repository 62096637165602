import { __decorate } from "tslib";
import { Component, Vue, Ref, Prop, Watch, } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { UserPageType, UserStatus, NodeType, } from '../../../typings/organization/org-manage';
import { CTable } from '@cloudpivot-hermes/common-components';
let StaffManage = class StaffManage extends Vue {
    constructor() {
        super(...arguments);
        this.UserStatus = UserStatus;
        this.organizationList = [];
        this.baseUserId = '';
        this.showUserPageType = UserPageType.UserList;
        this.UserPageType = UserPageType;
        this.NodeType = NodeType;
        this.tableColumns = [{
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'index', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'name',
                key: 'name',
                slots: { title: 'name', name: '姓名' },
                ellipsis: true,
                scopedSlots: { customRender: 'name' },
            },
            {
                dataIndex: 'username',
                key: 'username',
                ellipsis: true,
                slots: { title: 'code', name: '账号' },
            },
            {
                dataIndex: 'mobile',
                key: 'mobile',
                ellipsis: true,
                slots: { title: 'mobile', name: '手机号' },
                scopedSlots: { customRender: 'mobile' },
            },
            {
                dataIndex: 'organizationName',
                key: 'organizationName',
                ellipsis: true,
                slots: { title: 'organizationName', name: '所属组织' },
            },
        ];
        this.searchParams = {
            orgId: '',
            keyword: '',
            userStatus: this.userStatus,
        };
        this.keyword = '';
        this.multipleOrgParams = {};
    }
    get orgId() {
        return this.$route.params.orgId;
    }
    onKeywordChange() {
        this.searchParams.keyword = this.searchParams.keyword?.replace(/^\s*|\s*$/g, '');
    }
    mounted() {
        this.init();
    }
    async init() {
        await this.getOrganizationList();
        var timer = setInterval(() => {
            if (this.ctable) {
                this.ctable.getTableList();
                clearInterval(timer);
            }
        }, 300);
    }
    showUserDetail(row) {
        this.baseUserId = row.baseUserId;
        this.multipleOrgParams.baseUserId = row.baseUserId;
        this.multipleOrgParams.userStatus = this.userStatus;
        this.multipleOrgParams.orgId = row.orgId;
        this.showUserPageType = UserPageType.UserDetail;
    }
    async getOrganizationList() {
        const res = await OrgManageService.getSelectOrgList({ status: UserStatus.Enable });
        if (res.success) {
            this.organizationList = res.data?.map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
            this.organizationList.unshift({
                label: '全部组织',
                value: '',
            });
            if (this.orgId) {
                this.searchParams.orgId = this.orgId;
            }
            else {
                this.searchParams.orgId = this.organizationList[0]?.value;
            }
        }
    }
    /**
     * 获取列表数据接口
     */
    getList(params, options) {
        const p = {
            ...params,
            ...this.searchParams,
            page: params.page,
        };
        return new Promise((resolve) => {
            OrgManageService.getEnableList(p, options).then((res) => {
                resolve(res);
            });
        });
    }
    change() {
        this.search();
    }
    /**
     * 搜索
     */
    search(keyword = '') {
        this.searchParams.keyword = keyword;
        this.keyword = keyword;
        this.ctable.pagination.current = 1;
        this.ctable.getTableList();
    }
    async toggleComponent(v) {
        this.showUserPageType = v.showUserPageType;
    }
};
__decorate([
    Ref()
], StaffManage.prototype, "ctable", void 0);
__decorate([
    Prop()
], StaffManage.prototype, "userStatus", void 0);
__decorate([
    Watch('searchParams.keyword')
], StaffManage.prototype, "onKeywordChange", null);
StaffManage = __decorate([
    Component({
        name: 'StaffManage',
        components: {
            CTable,
            UserDetail: () => import('@/components/organization/org-manage/user-detail.vue'),
            Avatar: () => import('@/components/organization/org-manage/avatar.vue'),
        },
    })
], StaffManage);
export default StaffManage;
